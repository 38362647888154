import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import { StockService } from '../../services/stocks.service';
import { ProductService } from '../../services/product.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-create-stocks',
  templateUrl: './create-stocks.component.html',
})
export class CreateStocksComponent implements OnInit {
  singleStockForm: FormGroup;
  multipleStocksForm: FormGroup;
  products: any[];
  users: any[];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private snackBar: MatSnackBar,
    private stockService: StockService,
    private productService: ProductService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.singleStockForm = this.fb.group({
      productId: ['', Validators.required],
      userId: ['', Validators.required],
      initialQuantity: ['', [Validators.required, Validators.min(0)]],
    });

    this.multipleStocksForm = this.fb.group({
      userId: ['', Validators.required],
      products: this.fb.array([])
    });

    this.productService.getCompanyProducts().subscribe((data: any) => {
      if (data.message === 'success') {
        // TODO: Filter products based on the type later
        
        // this.products = data.res.products.filter((product) => product.type === 'sales');
        // this.products = data.res.products.filter((product) => product.price !== 0);
        this.products = data.res.products;
      } else {
        this.snackBar.open('Failed to load products', 'Close', {
          duration: 5000,
          verticalPosition: 'top',
        });
      }
    });

    this.userService.getCompanyUsers().subscribe((data: any) => {
      if (data.message === 'success') {
        this.users = data.res.companyUsers.filter((user) => ['Promoter', 'Sales Rep/Promoter'].includes(user.role));
      } else {
        this.snackBar.open('Failed to load users', 'Close', {
          duration: 5000,
          verticalPosition: 'top',
        });
      }
    });

    this.addProduct();
  }

  get productsArray(): FormArray {
    return this.multipleStocksForm.get('products') as FormArray;
  }

  addProduct(): void {
    this.productsArray.push(this.fb.group({
      productId: ['', Validators.required],
      initialQuantity: ['', [Validators.required, Validators.min(0)]]
    }));
  }

  removeProduct(index: number): void {
    this.productsArray.removeAt(index);
  }

  getAvailableProducts(index: number): any[] {
    const selectedProductIds = this.productsArray.controls.map(control => control.get('productId').value);
    return this.products.filter(product => !selectedProductIds.includes(product._id) || product._id === this.productsArray.at(index).get('productId').value);
  }

  onSubmitSingle(): void {
    if (this.singleStockForm.invalid) {
      this.snackBar.open('Please fill all the required fields', 'Close', {
        duration: 5000,
        verticalPosition: 'top',
      });
      return;
    }

    this.stockService.createStock(this.singleStockForm.value).subscribe(
      (data: any) => {
        if (data.message === 'success') {
          this.snackBar.open('Stock created successfully', 'Close', {
            duration: 5000,
            verticalPosition: 'top',
          });
          this.router.navigateByUrl('/stocks');
        }
      },
      (error) => {
        this.snackBar.open('Failed to create stock', 'Close', {
          duration: 5000,
          verticalPosition: 'top',
        });
      }
    );
  }

  onSubmitMultiple(): void {
    if (this.multipleStocksForm.invalid) {
      this.snackBar.open('Please fill all the required fields', 'Close', {
        duration: 5000,
        verticalPosition: 'top',
      });
      return;
    }

    const stocks = this.productsArray.value.map(product => ({
      productId: product.productId,
      initialQuantity: product.initialQuantity,
    }));

    const stocksData = {
      userId: this.multipleStocksForm.value.userId,
      stocks,
    };

    this.stockService.createMultipleStocks(stocksData).subscribe(
      (data: any) => {
        if (data.message === 'success') {
          if (data.res.invalidStocks.length > 0) {
            this.snackBar.open(
              `Created some stocks successfully, ${data.res.invalidStocks.length} stocks are duplicate`,
              'Close',
              {
                duration: 5000,
                verticalPosition: 'top',
              }
            );
          } else {
            this.snackBar.open('Stocks created successfully', 'Close', {
              duration: 5000,
              verticalPosition: 'top',
            });
          }
          this.router.navigateByUrl('/stocks');
        }
      },
      (error) => {
        if (error.error.invalidStocks.length > 0) {
          this.snackBar.open(
            `All stocks are duplicate, cannot overwrite existing stocks`,
            'Close',
            {
              duration: 5000,
              verticalPosition: 'top',
            }
          );
        } else {
          this.snackBar.open('Failed to create stocks', 'Close', {
            duration: 5000,
            verticalPosition: 'top',
          });
        }
      }
    );
  }
}