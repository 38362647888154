import { Component, OnInit } from '@angular/core';
import { MatSelectionListChange, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';

import { AuthenticationService } from '../../../services/authentication.service';
import { ProductService } from '../../../services/product.service';
import { LineService } from 'src/app/services/line.service';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';

@Component({
  selector: 'app-create-product',
  templateUrl: './create-product.component.html',
  styleUrls: ['./create-product.component.css']
})

export class CreateProductComponent implements OnInit{
  productForm: FormGroup;
  types = ['sales', 'marketing']
  
  lines: any[];
  iconFile: File | null = null;
  packageFile: File | null = null;
  screenshotFiles: File[] = [];
  pdfFile: File;

  salesFields = [
    'name',
    'catalogue',
    'price',
  ];

  marketingFields = [
    'name',
    'lineIds',
    'catalogueProducts',
    'competitors',
    'version',
    'isPortrait',
  ];

  salesCatalogueForm: FormGroup;

  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private authenticationService: AuthenticationService,
    private productService: ProductService,
    private lineService: LineService,
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit() {
    this.authenticationService.isLoggedInWithRedirect();

    this.productForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      type: ['', [Validators.required]],
      catalogue: [''],
      price: [''],
      lines: this.formBuilder.array([]),
      catalogueProducts: this.formBuilder.array([]),
      competitors: this.formBuilder.array([]),
      sections: this.formBuilder.array([]),
      isPortrait: [false],
    });
    this.addSection();

    this.salesCatalogueForm = this.formBuilder.group({
      catalogue: ['', [Validators.required]],
      products: this.formBuilder.array([]),
    });
    this.addSalesProduct();

    this.lineService
      .getLines()
      .subscribe((data: any) => {
        if(data.message === "success") {
          this.lines = data.res.lines;
          this.populateFormArray('lines', this.lines);
        } else {
          this.router.navigateByUrl('/products');
        }
      });

    this.productForm.get('type').valueChanges.subscribe((type: string) => {
      this.updateValidators(type);
    });
  }

  // #=========== ---- =========== Populate Form Array =========== ---- ===========# //
  populateFormArray(formArrayName: string, allValues: any[]): void {
    const formArray = this.productForm.get(formArrayName) as FormArray;
    allValues.forEach(value => { formArray.push(this.formBuilder.control(false)); });
  }

  // #=========== ---- =========== Get Type =========== ---- ===========# //
  getType(): string {
    return this.productForm.get('type').value;
  }

  // #=========== ---- =========== Update Validators =========== ---- ===========# //
  updateValidators(type: string): void {
    if (type === 'sales') {
      this.productForm.get('catalogue').setValidators([Validators.required]);
      this.productForm.get('price').setValidators([Validators.required, Validators.min(0)]);
      this.productForm.get('lines').clearValidators();
      this.productForm.get('catalogueProducts').clearValidators();
      this.productForm.get('competitors').clearValidators();
      this.productForm.get('sections').clearValidators();
      this.productForm.get('isPortrait').clearValidators();
    } else if (type === 'marketing') {
      this.productForm.get('catalogue').clearValidators();
      this.productForm.get('price').clearValidators();
      this.productForm.get('lines').setValidators([Validators.required]);
      this.productForm.get('catalogueProducts').clearValidators();
      this.productForm.get('competitors').clearValidators();
      this.productForm.get('sections').setValidators([Validators.required]);
      this.productForm.get('isPortrait').clearValidators();
    }

    this.productForm.get('catalogue').updateValueAndValidity();
    this.productForm.get('price').updateValueAndValidity();
    this.productForm.get('lines').updateValueAndValidity();
    this.productForm.get('catalogueProducts').updateValueAndValidity();
    this.productForm.get('competitors').updateValueAndValidity();
    this.productForm.get('sections').updateValueAndValidity();
    this.productForm.get('isPortrait').updateValueAndValidity();
  }

  addChip(event: KeyboardEvent, formControlName: string): void {
    event.preventDefault();
    const input = event.target as HTMLInputElement;
    const value = input.value.trim();

    if (value) {
      const formControl = this.productForm.get(formControlName) as FormArray;
      formControl.push(this.formBuilder.control(value));
      input.value = '';
    }
  }

  removeChip(index: number, formControlName: string): void {
    const formControl = this.productForm.get(formControlName) as FormArray;
    formControl.removeAt(index);
  }

  getSectionGroups(): FormArray {
    return this.productForm.get('sections') as FormArray;
  }

  addSection(): void {
    const sectionGroup = this.formBuilder.group({
      name: [''],
      slides: this.formBuilder.array([])
    });
    this.getSectionGroups().push(sectionGroup);
  }

  removeSection(index: number): void {
    this.getSectionGroups().removeAt(index);
  }

  addSlide(sectionIndex: number, event: KeyboardEvent): void {
    event.preventDefault();
    const input = event.target as HTMLInputElement;
    const value = input.value.trim();

    if (value) {
      const slides = this.getSectionGroups().at(sectionIndex).get('slides') as FormArray;
      slides.push(this.formBuilder.group({ name: value }));
      input.value = '';
    }
  }

  removeSlide(sectionIndex: number, slideIndex: number): void {
    const slides = this.getSectionGroups().at(sectionIndex).get('slides') as FormArray;
    slides.removeAt(slideIndex);
  }

  onFileChange(event: Event, fileType: string): void {
    const input = event.target as HTMLInputElement;
    
    if (input.files && input.files.length > 0) {
      switch (fileType) {
        case 'icon':
          this.iconFile = input.files[0];
          break;
        case 'package':
          this.packageFile = input.files[0];
          break;
        case 'pdf':
          this.pdfFile = input.files[0];
          break;
        case 'screenshots':
          this.screenshotFiles = Array.from(input.files);
          break;
        default:
          return;
      }
    }
  }

  getSelectedLines(): string[] {
    const selectedLines = this.productForm.value.lines;

    return this.lines
      .filter((line, index) => selectedLines[index])
      .map(line => line._id);
  }

  onCreateProduct() {
    const productData = this.productForm.value;
    const type = this.getType();
    let formData: FormData | any;

    if (type === 'marketing') {
      formData = new FormData();
      productData.lineIds = this.getSelectedLines();
      delete productData.lines;

      const version = { sections: productData.sections };
      delete productData.sections;
      productData.version = JSON.stringify(version);
      
      formData.append('name', productData.name);
      formData.append('icon', this.iconFile);
      
      if (this.packageFile) {
        const packageBlob = new Blob([this.packageFile], { type: 'application/zip' });
        formData.append('package', packageBlob, this.packageFile.name);
      }

      if (this.pdfFile) {
        const pdfBlob = new Blob([this.pdfFile], { type: 'application/pdf' });
        formData.append('pdf', pdfBlob, this.pdfFile.name);
      }

      if (this.screenshotFiles.length > 0) {
        this.screenshotFiles.forEach(file => { formData.append('screenshots', file) });
      }
      
      const marketingData = {};
      this.marketingFields.forEach(key => {
        marketingData[key] = productData[key];
      });
      formData.append('productData', JSON.stringify(marketingData));
    } else if (type === 'sales') {
      formData = {};
      this.salesFields.forEach(key => {
        formData[key] = productData[key];
      });
    }

    this.productService
      .createProduct(formData, type)
      .subscribe(
        (data: any) => {
          if (data.message === "success") {
            this.snackBar.open('Product created successfully', 'Close', {
              verticalPosition: 'top',
              duration: 3000,
            });
          } else {
            this.snackBar.open('Failed to create product', 'Close', {
              verticalPosition: 'top',
              duration: 3000,
            });
          }
          this.router.navigateByUrl('/products');
        }, (error) => {
          this.snackBar.open('Failed to create product', 'Close', {
            verticalPosition: 'top',
            duration: 3000,
          });
          this.router.navigateByUrl('/products');
        }
      )
  }

  addSalesProduct(): void {
    const productFormArray = this.salesCatalogueForm.get('products') as FormArray;

    const newProduct = this.formBuilder.group({
      name: ['', [Validators.required]],
      price: ['', [Validators.required]],
    });

    productFormArray.push(newProduct);
  }

  removeSalesProduct(index: number): void {
    const productFormArray = this.salesCatalogueForm.get('products') as FormArray;
    productFormArray.removeAt(index);
  }

  getSalesProducts(): FormArray {
    return this.salesCatalogueForm.get('products') as FormArray;
  }

  onCreateSalesCatalogue(): void {
    const productData = this.salesCatalogueForm.value; 
    
    this.productService.createSalesCatalogue(productData).subscribe(
      (data: any) => {
        if (data.message === "success") {
          this.snackBar.open('Sales catalogue created successfully', 'Close', {
            verticalPosition: 'top',
            duration: 3000,
          });

          this.router.navigateByUrl('/products');
        } else {
          this.snackBar.open('Failed to create sales catalogue', 'Close', {
            verticalPosition: 'top',
            duration: 3000,
          });
        }
        this.router.navigateByUrl('/products');
      }, (error) => {
        this.snackBar.open('Failed to create sales catalogue', 'Close', {
          verticalPosition: 'top',
          duration: 3000,
        });
        this.router.navigateByUrl('/products');
      }
    );
  }
}