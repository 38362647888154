import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

import { StockService } from '../../services/stocks.service';
import { ProductService } from '../../services/product.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-edit-stocks',
  templateUrl: './edit-stocks.component.html',
})

export class EditStocksComponent implements OnInit {
  stockForm: FormGroup;
  stockId: string;
  stock: any;
  products: any[];
  users: any[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private stockService: StockService,
    private productService: ProductService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.stockForm = this.fb.group({
      productId: ['', Validators.required],
      userId: ['', Validators.required],
      initialQuantity: ['', [Validators.required, Validators.min(0)]],
      currentQuantity: ['', [Validators.required, Validators.min(0)]]
    });

    this.stockId = this.route.snapshot.paramMap.get('id');
    
    this.stockService.getStock(this.stockId).subscribe((data: any) => {
      if (data.message === 'success') {
        this.stock = data.res.stock;
        
        this.stockForm.patchValue({
          productId: this.stock.productId._id,
          userId: this.stock.userId._id,
          initialQuantity: this.stock.initialQuantity,
          currentQuantity: this.stock.currentQuantity
        });
      } else {
        this.snackBar.open('Failed to load stock', 'Close', {
          duration: 5000,
          verticalPosition: 'top',
        });
        this.router.navigateByUrl('/stocks');
      }
    });
    
    this.productService.getCompanyProducts().subscribe((data: any) => {
      if (data.message === 'success') {
        // TODO: Filter products based on the type later
        
        // this.products = data.res.products.filter((product) => product.type === 'sales');
        // this.products = data.res.products.filter((product) => product.price !== 0);
        this.products = data.res.products;
      } else {
        this.snackBar.open('Failed to load products', 'Close', {
          duration: 5000,
          verticalPosition: 'top',
        });
      }
    });

    this.userService.getCompanyUsers().subscribe((data: any) => {
      if (data.message === 'success') {
        this.users = data.res.companyUsers.filter((user) => ['Promoter', 'Sales Rep/Promoter'].includes(user.role));
      } else {
        this.snackBar.open('Failed to load users', 'Close', {
          duration: 5000,
          verticalPosition: 'top',
        });
      }
    });
  }

  onSubmit(): void {
    if (this.stockForm.invalid) {
      this.snackBar.open('Please fill all the required fields', 'Close', {
        duration: 5000,
        verticalPosition: 'top',
      });

      return;
    }

    this.stockService.updateStock(this.stockId, this.stockForm.value).subscribe(
      (data: any) => {
        if (data.message === 'success') {
          this.snackBar.open('Stock updated successfully', 'Close', {
            duration: 5000,
            verticalPosition: 'top',
          });
          this.router.navigateByUrl('/stocks');
        }
      },
      (error) => {
        if (error.status === 409) {
          this.snackBar.open('Stock with the same properties already exists', 'Close', {
            duration: 5000,
            verticalPosition: 'top',
          });
        } else {
          this.snackBar.open('Failed to update stock', 'Close', {
            duration: 5000,
            verticalPosition: 'top',
          });
          this.router.navigateByUrl('/stocks');
        }
      }
    );
  }

  onDelete(): void {
    this.stockService.deleteStock(this.stockId).subscribe(
      (data: any) => {
        if (data.message === 'success') {
          this.snackBar.open('Stock deleted successfully', 'Close', {
            duration: 5000,
            verticalPosition: 'top',
          });
          this.router.navigateByUrl('/stocks');
        }
      },
      (error) => {
        this.snackBar.open('Failed to delete stock', 'Close', {
          duration: 5000,
          verticalPosition: 'top',
        });
        this.router.navigateByUrl('/stocks');
      }
    );
  }
}