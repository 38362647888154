// #=========== ---- =========== Import Angular Libraries =========== ---- ===========# //
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

// #=========== ---- =========== Import Services =========== ---- ===========# //
import { AuthenticationService } from "./authentication.service";

// #=========== ---- =========== Import Environment =========== ---- ===========# //
import { environment } from "../../environments/environment";

// #=========== ---- =========== Call Expiration Service =========== ---- ===========# //
@Injectable({
    providedIn: "root",
})

export class CallExpirationService {
    // TODO: Change to baseUrlV2 after publishing changes in the backend
    private baseUrl: String = `${environment.baseUrlV2}api-v2/call-expiration`;
    // private baseUrl: String = `${environment.devUrlV2}/call-expiration`;

    // #=========== ---- =========== Call Expiration Service constructor =========== ---- ===========# //
    constructor(
        public http: HttpClient,
        public authService: AuthenticationService
    ) {}

    // #=========== ---- =========== Call Expiration Service Methods =========== ---- ===========# //

    // #=========== ---- =========== Get Company Call Expirations =========== ---- ===========# //
    getCompanyCallExpirations(): Observable<any> {
        const token = this.authService.getToken();
        
        return this.http.get(`${this.baseUrl}/company/get`, {
            headers: { authorization: "Bearer " + token },
        })
    }

    // #=========== ---- =========== Get Call Expiration =========== ---- ===========# //
    getCallExpiration(callExpirationId: string): Observable<any> {
        const token = this.authService.getToken();

        return this.http.get(`${this.baseUrl}/get/${callExpirationId}`, {
            headers: { authorization: "Bearer " + token },
        });
    }

    // #=========== ---- =========== Update Call Expiration =========== ---- ===========# //
    updateCallExpiration(
        callExpirationId: string,
        callExpirationData: { countryId: string, expirationDays: number }
    ): Observable<any> {
        const token = this.authService.getToken();

        return this.http.put(
            `${this.baseUrl}/update/${callExpirationId}`,
            callExpirationData,
            {
                headers: { authorization: "Bearer " + token },
            }
        );
    }

    // #=========== ---- =========== Delete Call Expiration =========== ---- ===========# //
    deleteCallExpiration(callExpirationId: string): Observable<any> {
        const token = this.authService.getToken();

        return this.http.delete(`${this.baseUrl}/delete/${callExpirationId}`, {
            headers: { authorization: "Bearer " + token },
        });
    }

    // #=========== ---- =========== Create Call Expiration =========== ---- ===========# //
    createCallExpiration(callExpirationData: { countryId: string, expirationDays: number }): Observable<any> {
        const token = this.authService.getToken();

        return this.http.post(
            `${this.baseUrl}/create`,
            callExpirationData,
            {
                headers: { authorization: "Bearer " + token },
            }
        );
    }
}