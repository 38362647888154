// #=========== ---- =========== Import Angular Libraries =========== ---- ===========# //
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

// #=========== ---- =========== Import Services =========== ---- ===========# //
import { AuthenticationService } from "./authentication.service";

// #=========== ---- =========== Import Environment =========== ---- ===========# //
import { environment } from "../../environments/environment";

// #=========== ---- =========== Stock Service =========== ---- ===========# //
@Injectable({
  providedIn: "root",
})
export class StockService {
  private baseUrl: String = `${environment.baseUrlV2}api-v2/stocks`;
  // private baseUrl: string = `${environment.devUrlV2}/stocks`;

  // #=========== ---- =========== Stock Service Constructor =========== ---- ===========# //
  constructor(
    private http: HttpClient,
    private authService: AuthenticationService
  ) {}

  // #=========== ---- =========== Stock Service Methods =========== ---- ===========# //

  // #=========== ---- =========== Create Stock =========== ---- ===========# //
  createStock(stock: any): Observable<any> {
    const token = this.authService.getToken();

    return this.http.post(
      `${this.baseUrl}/create`,
      stock,
      { headers: { authorization: "Bearer " + token } }
    );
  }

  // #=========== ---- =========== Create Multiple Stocks =========== ---- ===========# //
  createMultipleStocks(stocks: any): Observable<any> {
    const token = this.authService.getToken();

    return this.http.post(
      `${this.baseUrl}/create-multiple`,
      stocks,
      { headers: { authorization: "Bearer " + token } }
    );
  }

  // #=========== ---- =========== Get Stock =========== ---- ===========# //
  getStock(stockId: string): Observable<any> {
    const token = this.authService.getToken();

    return this.http.get(
      `${this.baseUrl}/get/${stockId}`,
      { headers: { authorization: "Bearer " + token } }
    );
  }

  // #=========== ---- =========== Get Company Stocks =========== ---- ===========# //
  getCompanyStocks(): Observable<any> {
    const token = this.authService.getToken();

    return this.http.get(
      `${this.baseUrl}/get-company`,
      { headers: { authorization: "Bearer " + token } }
    );
  }

  // #=========== ---- =========== Update Stock =========== ---- ===========# //
  updateStock(stockId: string, stock: any): Observable<any> {
    const token = this.authService.getToken();

    return this.http.put(
      `${this.baseUrl}/update/${stockId}`,
      stock,
      { headers: { authorization: "Bearer " + token } }
    );
  }

  // #=========== ---- =========== Delete Stock =========== ---- ===========# //
  deleteStock(stockId: string): Observable<any> {
    const token = this.authService.getToken();

    return this.http.put(
      `${this.baseUrl}/delete/${stockId}`,
      null,
      { headers: { authorization: "Bearer " + token } }
    );
  }
}