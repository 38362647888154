import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule }   from '@angular/forms';

import { 
  MatButtonToggleModule,
  MatSnackBarModule,
  MatDatepickerModule,
  MatCheckboxModule,
  MatSidenavModule,
  MatButtonModule,
  MatListModule,MatIconModule,
  MatTableModule,
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  MatCardModule,
  MatDividerModule,
  MatExpansionModule,
  MatTabsModule,
  MatNativeDateModule,
  MatGridListModule,
  MatChip,
  MatChipList,
  MatChipsModule,

} from '@angular/material';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatRadioModule } from '@angular/material/radio';

import { AppComponent } from './app.component';

import { CustomerServiceComponent } from './customer-service/customer-service.component';
import { TicketComponent } from './customer-service/ticket/ticket.component';

import { LoginComponent } from './login/login.component';

import { UsersComponent } from './users/users.component';
import { CreateUserComponent } from './users/user/create/create-user.component';
import { EditUserComponent } from './users/user/edit/edit-user.component';

import { EmailsComponent } from './emails/emails.component';
import { CreateEmailComponent } from './emails/create/create-email.component';
import { EmailEditorComponent } from './emails/email-editor/email-editor.component';
import { SendEmailComponent } from './emails/send/send-email.component';
import { ReportEmailComponent } from './emails/report/report-email.component';
import { EditEmailComponent } from './emails/edit-email/edit-email.component';

import { ProductsComponent } from './products/products.component';
import { EditProductComponent } from './products/product/edit/edit-product.component';
import { CreateProductComponent } from './products/product/create/create-product.component';

import { SmsComponent } from './sms/sms.component';

import { LocationsComponent } from './locations/locations.component';

import { LinesComponent } from './lines/lines.component';
import { CreateLineComponent } from './lines/line/create/create-line.component';
import { EditLineComponent } from './lines/line/edit/edit-line.component';

import { TestimonialsComponent } from './testimonials/testimonials.component';

import { SpecialtiesComponent } from './specialties/specialties.component';
import { EditSpecialtyComponent } from './specialties/specialty/edit/edit-specialty.component';
import { CreateSpecialityComponent } from './specialties/specialty/create/create-specialty.component';

import { CallExpirationsComponent } from './call-expirations/call-expirations.component';
import { CreateCallExpirationComponent } from './call-expirations/call-expiration/create/create-call-expiration.component';
import { EditCallExpirationComponent } from './call-expirations/call-expiration/edit/edit-call-expiration.component';

import { LeaveManagementTemplatesComponent } from './leave-management-template/leave-management-template.component';
import { EditLeaveManagementTemplateComponent } from './leave-management-template/leave-management-template/edit/edit-leave-management-template.component';
import { CreateLeaveManagementTemplateComponent } from './leave-management-template/leave-management-template/create/create-leave-management-template.component';

import { UserPoliciesComponent } from './user-policies/user-policies.component';
import { EditUserPolicyComponent } from './user-policies/user-policy/edit/edit-user-policy.component';
import { CreateUserPolicyComponent } from './user-policies/user-policy/create/create-user-policy.component';

import { CompaniesComponent } from './companies/companies.component';
import { CreateCompanyComponent } from './companies/company/create/create-company.component';
import { EditCompanyComponent } from './companies/company/edit/edit-company.component';

import { StocksComponent } from './stocks/stocks.component';
import { EditStocksComponent } from './stocks/edit/edit-stocks.component';
import { CreateStocksComponent } from './stocks/create/create-stocks.component';

const appRoutes: Routes = [
  { path: 'users', component: UsersComponent },
  { path: 'users/create', component: CreateUserComponent},
  { path: 'users/:userId', component: EditUserComponent},

  { path: 'emails/send/:emailId', component: SendEmailComponent },
  { path: 'emails/report/:emailId', component: ReportEmailComponent },
  { path: 'emails/create', component: CreateEmailComponent },
  { path: 'emails', component: EmailsComponent },
  { path: 'emails/editor', component: EmailEditorComponent },
  { path: 'emails/edit-email/:emailId', component: EditEmailComponent},

  { path: 'sms', component: SmsComponent },

  { path: 'customer-service', component: CustomerServiceComponent },
  { path: 'customer-service/:ticketId', component: TicketComponent },

  { path: 'products', component: ProductsComponent },
  { path: 'products/create', component: CreateProductComponent },
  { path: 'products/:productId', component: EditProductComponent },

  { path: 'locations', component: LocationsComponent },
  
  { path: 'lines', component: LinesComponent},
  { path: 'lines/create', component: CreateLineComponent},
  { path: 'lines/:lineId', component: EditLineComponent},

  { path: 'testimonials', component: TestimonialsComponent },
  
  { path: 'login', component: LoginComponent },

  { path: 'specialities', component: SpecialtiesComponent },
  { path: 'specialities/create', component: CreateSpecialityComponent },
  { path: 'specialities/:specialityId', component: EditSpecialtyComponent },

  { path: 'call-expirations', component: CallExpirationsComponent },
  { path: 'call-expirations/create', component: CreateCallExpirationComponent },
  { path: 'call-expirations/:callExpirationId', component: EditCallExpirationComponent },

  { path: 'leave-management-templates', component: LeaveManagementTemplatesComponent },
  { path: 'leave-management-templates/create', component: CreateLeaveManagementTemplateComponent },
  { path: 'leave-management-templates/:leaveManagementTemplateId', component: EditLeaveManagementTemplateComponent },

  { path: 'user-policies', component: UserPoliciesComponent },
  { path: 'user-policies/create', component: CreateUserPolicyComponent },
  { path: 'user-policies/:role', component: EditUserPolicyComponent },

  { path: 'companies', component: CompaniesComponent },
  { path: 'companies/create', component: CreateCompanyComponent },
  { path: 'companies/:companyId', component: EditCompanyComponent },

  {  path: 'stocks', component: StocksComponent },
  {  path: 'stocks/create', component: CreateStocksComponent },
  {  path: 'stocks/:id', component: EditStocksComponent },
 
  { path: '', component: LoginComponent },
  { path: '**', component: LoginComponent },
]

@NgModule({
  declarations: [
    AppComponent,

    CustomerServiceComponent,
    TicketComponent,
    
    LoginComponent,
    
    UsersComponent,
    CreateUserComponent,
    EditUserComponent,
    
    EmailsComponent,
    CreateEmailComponent,
    EmailEditorComponent,
    EditEmailComponent,
    SendEmailComponent,
    ReportEmailComponent,
    
    ProductsComponent,
    EditProductComponent,
    CreateProductComponent,
    
    SmsComponent,
    
    LocationsComponent,
    
    LinesComponent,
    CreateLineComponent,
    EditLineComponent,
    
    TestimonialsComponent,
    
    SpecialtiesComponent,
    CreateSpecialityComponent,
    EditSpecialtyComponent,
    
    CallExpirationsComponent,
    CreateCallExpirationComponent,
    EditCallExpirationComponent,
    
    LeaveManagementTemplatesComponent,
    EditLeaveManagementTemplateComponent,
    CreateLeaveManagementTemplateComponent,
    
    UserPoliciesComponent,
    EditUserPolicyComponent,
    CreateUserPolicyComponent,

    CompaniesComponent,
    CreateCompanyComponent,
    EditCompanyComponent,

    StocksComponent,
    EditStocksComponent,
    CreateStocksComponent,
  ],

  imports: [
    BrowserModule,
    MatSnackBarModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    MatButtonModule,
    MatListModule,
    MatIconModule,
    HttpClientModule,
    MatTableModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatCardModule,
    MatDividerModule,
    MatExpansionModule,
    MatTabsModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonToggleModule,
    MatProgressSpinnerModule,
    MatGridListModule,
    MatChipsModule,
    ReactiveFormsModule,
    MatMomentDateModule,
    RouterModule.forRoot(
      appRoutes, {useHash: true}
    ),
    MatRadioModule,
  ],
 
  providers: [],
  bootstrap: [AppComponent]
})

export class AppModule { }