// #=========== ---- =========== Import Angular Libraries =========== ---- ===========# //
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

// #=========== ---- =========== Import Services =========== ---- ===========# //
import { AuthenticationService } from "./authentication.service";

// #=========== ---- =========== Import Environment =========== ---- ===========# //
import { environment } from "../../environments/environment";

// #=========== ---- =========== Leave Management Template Service =========== ---- ===========# //
@Injectable({
    providedIn: "root",
})

export class LeaveManagementTemplateService {
    // TODO: Change to baseUrlV2 after publishing changes in the backend
    private baseUrl: String = `${environment.baseUrlV2}api-v2/leave-management-template`;
    // private baseUrl: String = `${environment.devUrlV2}/leave-management-template`;

    // #=========== ---- =========== Leave Management Service constructor =========== ---- ===========# //
    constructor(
        public http: HttpClient,
        public authService: AuthenticationService
    ) {}

    // #=========== ---- =========== Leave Management Service Methods =========== ---- ===========# //

    // #=========== ---- =========== Get Company Leave Management Templates =========== ---- ===========# //
    getCompanyLeaveManagementTemplates(): Observable<any> {
        const token = this.authService.getToken();
        
        return this.http.get(`${this.baseUrl}/get-company`, {
            headers: { authorization: "Bearer " + token },
        })
    }

    // #=========== ---- =========== Get Leave Management Template =========== ---- ===========# //
    getLeaveManagementTemplate(leaveManagementTemplateId: string): Observable<any> {
        const token = this.authService.getToken();

        return this.http.get(
            `${this.baseUrl}/get/${leaveManagementTemplateId}`,
            { headers: { authorization: "Bearer " + token } }
        );
    }

    // #=========== ---- =========== Edit Leave Management Template =========== ---- ===========# //
    editLeaveManagementTemplate(countryId, leaveManagementTemplateId: string, leaveManagementTemplate: any): Observable<any> {
        const token = this.authService.getToken();

        return this.http.put(
            `${this.baseUrl}/update/${countryId}/${leaveManagementTemplateId}`,
            leaveManagementTemplate,
            { headers: { authorization: "Bearer " + token } }
        );
    }

    // #=========== ---- =========== Delete Leave Management Template =========== ---- ===========# //
    deleteLeaveManagementTemplate(leaveManagementTemplateId: string): Observable<any> {
        const token = this.authService.getToken();

        return this.http.delete(
            `${this.baseUrl}/delete/${leaveManagementTemplateId}`,
            { headers: { authorization: "Bearer " + token } }
        );
    }

    // #=========== ---- =========== Create Leave Management Template =========== ---- ===========# //
    createLeaveManagementTemplate(countryId, leaveManagementTemplate: any): Observable<any> {
        const token = this.authService.getToken();

        return this.http.post(
            `${this.baseUrl}/create/${countryId}`,
            leaveManagementTemplate,
            { headers: { authorization: "Bearer " + token } }
        );
    }
}