import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatTableDataSource, MatSnackBar } from '@angular/material';
import {
  trigger,
  state,
  transition,
  style,
  animate,
} from "@angular/animations";

import { AuthenticationService } from '../services/authentication.service';
import { StockService } from '../services/stocks.service';

@Component({
  selector: 'app-stocks',
  templateUrl: './stocks.component.html',
})

export class StocksComponent implements OnInit {
  stocks: any[];
  displayedColumns = [
    'productName',
    'user',
    'username',
    'initialQuantity',
    'currentQuantity',
  ];
  stocksDisplayed: MatTableDataSource<any>;
  filter: any = {};
  products: string[];
  users: string[];

  constructor(
    private authenticationService: AuthenticationService,
    private stockService: StockService,
    private router: Router,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    this.authenticationService.isLoggedInWithRedirect();
    
    this.stockService
      .getCompanyStocks()
      .subscribe((data: any) => {
        if (data.message === "success") {
          this.stocks = data.res.stocks;
          this.stocksDisplayed = new MatTableDataSource(this.stocks);
          this.stocksDisplayed.filterPredicate = this.filterPredicate;

          const uniqueProducts: Set<string> = new Set(data.res.stocks.map((stock) => stock.productId.name));
          const uniqueUsers: Set<string> = new Set(data.res.stocks.map((stock) => stock.userId.name));

          this.products = Array.from(uniqueProducts);
          this.users = Array.from(uniqueUsers);
        } else {
          this.snackBar.open('Failed to get stocks', 'Close', {
            duration: 5000,
            verticalPosition: 'top',
          });
        }
      });
  }

  filterPredicate(data: any, filter: string): boolean {
    const isProductMatch = data.productId.name.toLowerCase().includes(filter);
    const isUserMatch = data.userId.name.toLowerCase().includes(filter);

    return isProductMatch || isUserMatch;
  }

  applyFilter() {
    let filteringData = this.stocks;
    
    for (let attr in this.filter) {
      if (!this.filter[attr]) {
        continue;
      }

      if (attr === 'productName') {
        filteringData = filteringData.filter((stock) => stock.productId.name.toLowerCase().includes(this.filter[attr].toLowerCase()));
      }

      if (attr === 'user') {
        filteringData = filteringData.filter((stock) => stock.userId.name.toLowerCase().includes(this.filter[attr].toLowerCase()));
      }
    }

    this.stocksDisplayed = new MatTableDataSource(filteringData);
  }

  search(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();
    this.stocksDisplayed.filter = filterValue;
  }

  goToStock(stock: any) {
    this.router.navigateByUrl(`/stocks/${stock._id}`);
  }

  goToCreateStock() {
    this.router.navigateByUrl('/stocks/create');
  }
}