import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SmsService
{
  constructor(public http: HttpClient) { }

  public sendSms(language: number, senderId: string, mobileNumber: string, message: string): Observable<any>
  {
    console.log(`https://smsmisr.com/api/webapi/?username=96c4c2af7168b8cbf6f401ba42843efc6e974786c5fd16ab4a6eb337993814d3&password=69814ea2147ad024a3238011ec9227e24b9cba60d956ec349f6c20419ed48323&language=${language}&sender=${senderId}&mobile=${mobileNumber}&message=${message}`);
    return this.http.post(`https://smsmisr.com/api/webapi/?username=96c4c2af7168b8cbf6f401ba42843efc6e974786c5fd16ab4a6eb337993814d3&password=711df69371005e0ad85a3bb0fdae97d2417786a10514887abcc21d89e95c6358&language=${language}&sender=${senderId}&mobile=${mobileNumber}&message=${message}`,{});
  }
}
