import { Component, OnInit } from '@angular/core';
import { MatSelectChange, MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthenticationService } from '../../../services/authentication.service';
import { ProductService } from '../../../services/product.service';
import { LineService } from 'src/app/services/line.service';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-edit-product',
  templateUrl: './edit-product.component.html',
  styleUrls: ['./edit-product.component.css']
})

export class EditProductComponent implements OnInit{
  productForm: FormGroup;
  product: any;
  types = ['sales', 'marketing']
  lines: any[];
  
  versionForm: FormGroup;
  versionsMenu: any[];
  selectedVersion: any = null;

  iconFile: File | null = null;
  packageFile: File | null = null;
  screenshotFiles: File[] = [];
  pdfFile: File;

  salesFields = [
    'name',
    'price',
  ];

  marketingFields = [
    'name',
    'lineIds',
    'isPortrait',
    'catalogueProducts',
    'competitors',
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private authenticationService: AuthenticationService,
    private productService: ProductService,
    private lineService: LineService,
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit() {
    this.authenticationService.isLoggedInWithRedirect();
    const productId = this.route.snapshot.paramMap.get('productId');

    this.productForm = this.formBuilder.group({
      name: [''],
      type: [''],
      price: [''],
      catalogue: [''],
      lines: this.formBuilder.array([]),
      catalogueProducts: this.formBuilder.array([]),
      competitors: this.formBuilder.array([]),
      isPortrait: [false],
    });

    this.versionForm = this.formBuilder.group({
      number: [''],
      sections: this.formBuilder.array([]),
    });

    this.addSection();

    forkJoin([
      this.lineService.getLines(),
      this.productService.getProduct(productId)
    ]).subscribe(([lines, product]: [any, any]) => {  
      if (lines.message === "success") {
        this.lines = lines.res.lines;
      } else {
        this.router.navigateByUrl('/products');
        return;
      }
  
      if (product.message === "success") {
        this.product = product.res.product;
  
        this.versionsMenu = this.product.versions
          .map((version: any) => ({ name: `Version ${version.number}`, value: version.number }));
        
        this.populateProductForm();
      } else {
        this.snackBar.open('Failed to get product', 'Close', {
          verticalPosition: 'top',
          duration: 5000,
        });
        this.router.navigateByUrl('/products');
      }
    });
  }

  // #=========== ---- =========== Populate Form Array =========== ---- ===========# //
  populateProductForm(): void {
    this.productForm.get('name').setValue(this.product.name);
    this.productForm.get('type').setValue(this.product.type);
    this.productForm.get('catalogue').setValue(this.product.catalogue);
    this.productForm.get('catalogue').disable();
    this.productForm.get('price').setValue(this.product.price);
    this.productForm.get('isPortrait').setValue(this.product.isPortrait);

    const linesFormArray = this.productForm.get('lines') as FormArray;
    this.lines.forEach((line) => {
      const isSelected = line.products.find((lineProduct: any) => {
        return lineProduct._id === this.product._id
      }) ? true : false;

      linesFormArray.push(this.formBuilder.control(isSelected));
    });

    const catalogueProductsFormArray = this.productForm.get('catalogueProducts') as FormArray;
    this.product.catalogueProducts.forEach((catalogueProduct: string) => {
      catalogueProductsFormArray.push(this.formBuilder.control(catalogueProduct));
    });

    const competitorsFormArray = this.productForm.get('competitors') as FormArray;
    this.product.competitors.forEach((competitor: string) => {
      competitorsFormArray.push(this.formBuilder.control(competitor));
    });
  }

  getLatestVersion(): any {
    return this.product.versions.length + 1;
  }

  onSelectVersion(event: MatSelectChange): void {
    const versionNumber = event.value;
    this.selectedVersion = this.product.versions.find((version: any) => version.number === versionNumber);
    
    const sectionsFormArray = this.versionForm.get('sections') as FormArray;
    while (sectionsFormArray.length) {
      sectionsFormArray.removeAt(0);
    }
    
    this.selectedVersion.sections.forEach((section: any) => {
      const sectionGroup = this.formBuilder.group({
        name: section.name,
        slides: this.formBuilder.array([]),
      });
      sectionsFormArray.push(sectionGroup);

      section.slides.forEach((slide: any) => {
        const slidesFormArray = sectionGroup.get('slides') as FormArray;
        slidesFormArray.push(this.formBuilder.group({ name: slide.name }));
      });
    });
  }

  // #=========== ---- =========== Get Type =========== ---- ===========# //
  getType(): string {
    return this.productForm.get('type').value;
  }

  addChip(event: KeyboardEvent, formControlName: string): void {
    event.preventDefault();
    const input = event.target as HTMLInputElement;
    const value = input.value.trim();

    if (value) {
      const formControl = this.productForm.get(formControlName) as FormArray;
      formControl.push(this.formBuilder.control(value));
      input.value = '';
    }
  }

  removeChip(index: number, formControlName: string): void {
    const formControl = this.productForm.get(formControlName) as FormArray;
    formControl.removeAt(index);
  }

  getSectionGroups(): FormArray {
    return this.versionForm.get('sections') as FormArray;
  }

  addSection(): void {
    const sectionGroup = this.formBuilder.group({
      name: [''],
      slides: this.formBuilder.array([])
    });
    this.getSectionGroups().push(sectionGroup);
  }

  removeSection(index: number): void {
    this.getSectionGroups().removeAt(index);
  }

  addSlide(sectionIndex: number, event: KeyboardEvent): void {
    event.preventDefault();
    const input = event.target as HTMLInputElement;
    const value = input.value.trim();

    if (value) {
      const slides = this.getSectionGroups().at(sectionIndex).get('slides') as FormArray;
      slides.push(this.formBuilder.group({ name: value }));
      input.value = '';
    }
  }

  removeSlide(sectionIndex: number, slideIndex: number): void {
    const slides = this.getSectionGroups().at(sectionIndex).get('slides') as FormArray;
    slides.removeAt(slideIndex);
  }

onFileChange(event: Event, fileType: string): void {
    const input = event.target as HTMLInputElement;
    
    if (input.files && input.files.length > 0) {
      switch (fileType) {
        case 'icon':
          this.iconFile = input.files[0];
          break;
        case 'package':
          this.packageFile = input.files[0];
          break;
        case 'pdf':
          this.pdfFile = input.files[0];
          break;
        case 'screenshots':
          this.screenshotFiles = Array.from(input.files);
          break;
        default:
          return;
      }
    }
  }

  getSelectedLines(): string[] {
    const selectedLines = this.productForm.value.lines;

    return this.lines
      .filter((line, index) => selectedLines[index])
      .map(line => line._id);
  }

  onUpdateProduct() {
    const productData = this.productForm.value;
    const type = productData.type;
    delete productData.type;

    if (type === 'marketing') {
      productData.lineIds = this.getSelectedLines();

      delete productData.lines;
      delete productData.catalogue;
      delete productData.price;
    } else if (type === 'sales') {
      productData.price = parseFloat(productData.price);

      delete productData.catalogue;
      delete productData.catalogueProducts;
      delete productData.competitors;
      delete productData.isPortrait;
      delete productData.type;
      delete productData.lines;
    }

    this.productService
      .updateProduct(this.product._id, type, productData)
      .subscribe((data: any) => {
        if (data.message === "success") {
          this.snackBar.open('Product Updated successfully', 'Close', {
            verticalPosition: 'top',
            duration: 3000,
          });
        } else {
          this.snackBar.open('Failed to update product, try again later', 'Close', {
            verticalPosition: 'top',
            duration: 3000,
          });
        }
        this.router.navigateByUrl('/products');
      });
  }

  onDeleteProduct() {
    this.productService
      .deleteProduct(this.product._id)
      .subscribe((data: any) => {
        if (data.message === "success") {
          this.snackBar.open('Product deleted successfully', 'Close', {
            verticalPosition: 'top',
            duration: 3000,
          });
        } else {
          this.snackBar.open('Failed to delete product', 'Close', {
            verticalPosition: 'top',
            duration: 3000,
          });
        }
        this.router.navigateByUrl('/products');
      });
  }

  onAddVersion() {
    const productData = new FormData();
    productData.append('name', this.product.name);
    
    const version = this.versionForm.value;
    delete version.number;
    productData.append('version', JSON.stringify(version));

    if (this.iconFile) {
      productData.append('icon', this.iconFile);
    }
    if (this.packageFile) {
      const packageBlob = new Blob([this.packageFile], { type: 'application/zip' });
      productData.append('package', packageBlob, this.packageFile.name);
    }

    if (this.pdfFile) {
      const pdfBlob = new Blob([this.pdfFile], { type: 'application/pdf' });
      productData.append('pdf', pdfBlob, this.pdfFile.name);
    }

    if (this.screenshotFiles.length > 0) {
      this.screenshotFiles.forEach(file => { productData.append('screenshots', file) });
    }

    this.productService
      .addVersion(this.product._id, productData)
      .subscribe((data: any) => {
        if (data.message === "success") {
          this.snackBar.open('Version added successfully', 'Close', {
            verticalPosition: 'top',
            duration: 3000,
          });
          this.router.navigateByUrl('/products');
        } else {
          this.snackBar.open('Failed to add version, try again later', 'Close', {
            verticalPosition: 'top',
            duration: 3000,
          });
        }
      });
  }

  onEditVersion() {
    const productData = new FormData();
    productData.append('name', this.product.name);
    productData.append('version', JSON.stringify(this.versionForm.value));

    if (this.iconFile) {
      productData.append('icon', this.iconFile);
    }
    if (this.packageFile) {
      const packageBlob = new Blob([this.packageFile], { type: 'application/zip' });
      productData.append('package', packageBlob, this.packageFile.name);
    }

    if (this.pdfFile) {
      const pdfBlob = new Blob([this.pdfFile], { type: 'application/pdf' });
      productData.append('pdf', pdfBlob, this.pdfFile.name);
    }

    if (this.screenshotFiles.length > 0) {
      this.screenshotFiles.forEach(file => { productData.append('screenshots', file) });
    }

    this.productService
      .editVersion(this.product._id, productData)
      .subscribe((data: any) => {
        if (data.message === "success") {
          this.snackBar.open('Version edited successfully', 'Close', {
            verticalPosition: 'top',
            duration: 3000,
          });
          this.router.navigateByUrl('/products');
        } else {
          this.snackBar.open('Failed to edit version, try again later', 'Close', {
            verticalPosition: 'top',
            duration: 3000,
          });
        }
      });
  }
}